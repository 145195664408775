import * as data from './data';
export const formatMs = (msTime) => {
    const date = new Date(Math.max(0, msTime + 500)), hours = date.getUTCHours(), minutes = date.getUTCMinutes(), seconds = date.getUTCSeconds();
    return hours > 0 ? hours + ':' + pad(minutes) + ':' + pad(seconds) : minutes + ':' + pad(seconds);
};
export const otbClockIsRunning = (fen) => !fen.includes('PPPPPPPP/RNBQKBNR');
export const lichessClockIsRunning = (fen, color) => color == 'white' ? !fen.includes('PPPPPPPP/RNBQKBNR') : !fen.startsWith('rnbqkbnr/pppppppp');
export function setClockWidget(el, opts) {
    const instance = data.get(el, 'clock');
    if (instance)
        instance.set(opts);
    else
        data.set(el, 'clock', new ClockWidget(el, opts));
}
const pad = (x) => (x < 10 ? '0' : '') + x;
class ClockWidget {
    constructor(el, opts) {
        this.el = el;
        this.opts = opts;
        this.set = (opts) => {
            this.opts = opts;
            this.target = opts.time * 1000 + Date.now();
            this.render();
            clearInterval(this.interval);
            if (!opts.pause)
                this.interval = setInterval(this.render, 1000);
        };
        this.render = () => {
            if (document.body.contains(this.el)) {
                this.el.textContent = formatMs(this.target - Date.now());
                this.el.classList.toggle('clock--run', !this.opts.pause);
            }
            else
                clearInterval(this.interval);
        };
        this.target = opts.time * 1000 + Date.now();
        if (!opts.pause)
            this.interval = setInterval(this.render, 1000);
        this.render();
    }
}
